// Weights

$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

// Headings

@mixin heading-0 {
  font-size: 32px;
  font-weight: $font-weight-semibold;
  line-height: 40px;
}

@mixin heading-1 {
  font-size: 24px;
  font-weight: $font-weight-semibold;
  line-height: 30px;
}

@mixin heading-2 {
  font-size: 20px;
  font-weight: $font-weight-semibold;
  line-height: 24px;
}

@mixin heading-3 {
  font-size: 16px;
  font-weight: $font-weight-semibold;
  line-height: 20px;
}

// Subheaders

@mixin subheader-1 {
  font-size: 14px;
  font-weight: $font-weight-semibold;
  line-height: 16px;
}

@mixin subheader-2 {
  font-size: 12px;
  font-weight: $font-weight-semibold;
  line-height: 14px;
}

@mixin subheader-3 {
  font-size: 10px;
  font-weight: $font-weight-semibold;
  line-height: 12px;
}

// Body & helpers

@mixin body-1 {
  font-size: 16px;
  font-weight: $font-weight-regular;
  line-height: 24px;
}

@mixin body-2 {
  font-size: 14px;
  font-weight: $font-weight-regular;
  line-height: 20px;
}

@mixin body-3 {
  font-size: 12px;
  font-weight: $font-weight-regular;
  line-height: 16px;
}

@mixin helper-1 {
  font-size: 12px;
  font-weight: $font-weight-regular;
  line-height: 16px;
}

@mixin body-1-medium {
  font-size: 16px;
  font-weight: $font-weight-medium;
  line-height: 24px;
}

@mixin body-2-medium {
  font-size: 14px;
  font-weight: $font-weight-medium;
  line-height: 20px;
}

@mixin body-2-bold {
  font-size: 14px;
  font-weight: $font-weight-semibold;
  line-height: 20px;
}

@mixin helper-1-medium {
  font-size: 12px;
  font-weight: $font-weight-medium;
  line-height: 16px;
}

@mixin growth-font {
  font-family: Sharp-Grotesk-Medium, var(--system-ui);
}

@mixin growth-heading-1 {
  @include growth-font;

  font-size: 64px;
  font-weight: $font-weight-medium;
  line-height: 1.2em;
  letter-spacing: -0.01em;
}

@mixin growth-heading-2 {
  @include growth-font;

  font-size: 56px;
  font-weight: $font-weight-medium;
  line-height: 1.2em;
  letter-spacing: -0.01em;
}

@mixin growth-heading-3 {
  @include growth-font;

  font-size: 48px;
  font-weight: $font-weight-medium;
  line-height: 1.2em;
  letter-spacing: 0;
}

@mixin growth-heading-4 {
  @include growth-font;

  font-size: 36px;
  font-weight: $font-weight-medium;
  line-height: 1.3em;
  letter-spacing: 0.01em;
}

@mixin growth-heading-5 {
  @include growth-font;

  font-size: 28px;
  font-weight: $font-weight-medium;
  line-height: 1.5em;
  letter-spacing: 0.01em;
}

@mixin growth-mini-heading {
  @include growth-font;

  font-size: 20px;
  font-weight: $font-weight-medium;
  line-height: 1.4em;
  letter-spacing: 0.01em;
}
