@use 'typography';
@import 'assets/common/scss/variables';

.default {
  cursor: pointer;
  font-weight: $font-weight-regular;
  color: var(--color-primary-50);
  text-decoration: none;

  &:hover,
  &.hover {
    color: var(--color-text-links);
    text-decoration: none;

    &:not(:global(.zp-button)) {
      :global(.zp-icon) {
        color: var(--zp-icon-hover-color, --color-primary-60);
      }
    }
  }

  &.disabled {
    &:hover {
      cursor: not-allowed;
      color: var(--color-neutral-40);
    }
  }

  &:active,
  &.active {
    color: var(--color-primary-70);

    &:not(:global(.zp-button)) {
      :global(.zp-icon) {
        color: var(--zp-icon-active-color, --color-primary-70);
      }
    }
  }

  &.red {
    color: var(--color-negative-40);
  }
}

.muted {
  text-decoration: underline;
}

.title {
  @include typography.subheader-2;
  text-transform: uppercase;
}

.action {
  @include typography.helper-1;
  text-decoration: underline;
}

.muted,
.title,
.action {
  color: var(--color-text-secondary);

  &:hover,
  &.hover {
    color: var(--color-primary-50);
  }
}
